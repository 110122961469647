import { gql } from '@apollo/client/core'
import { useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import type { CurrentUserChatsQuery } from '@/src/graphql/operations'
import { CurrentUserChatsDocument } from '@/src/graphql/operations'
import type { RelayNode } from '@/src/graphql/typeUtil'
import { useBoolean } from '@/src/hooks/useBoolean'
import type { UseConnectionResultReturn } from '@/src/hooks/useConnectionResult'
import { useConnectionResult } from '@/src/hooks/useConnectionResult'

export type ChatNode = RelayNode<CurrentUserChatsQuery['currentUser']['chats']>

gql`
  query currentUserChats($chatFilters: ChatFilters!, $first: Int, $last: Int, $after: String, $before: String) {
    currentUser {
      id
      chats(chatFilters: $chatFilters, first: $first, last: $last, after: $after, before: $before) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            sourceTypeText
            unread
            latestMessage {
              id
              ...chatMessageBasicInfo
            }
            ...chatSource
          }
        }
      }
    }
  }
`

type UseLazyChatsReturn = {
  chats: UseConnectionResultReturn<ChatNode>['nodes']
  loading: Ref<boolean>
  refetch: () => void
  enable: () => void
}

export const useLazyCurrentUserChats = (): UseLazyChatsReturn => {
  const [enabled, { on: enable }] = useBoolean(false)
  const { result, loading, refetch } = useQuery(
    CurrentUserChatsDocument,
    {
      chatFilters: { unread: false },
      first: 5,
    },
    () => ({
      enabled: enabled.value,
    }),
  )

  const connection = computed(() => result?.value?.currentUser.chats)
  const { nodes } = useConnectionResult(connection)

  return { chats: nodes, loading, refetch, enable }
}

type UseCurrentUserChatsConnectionReturn = UseConnectionResultReturn<ChatNode> & {
  loading: Ref<boolean>
}

export const useCurrentUserChatsConnection = (
  {
    before,
    after,
  }: {
    before?: Ref<string | undefined>
    after?: Ref<string | undefined>
  },
  perPage: number,
): UseCurrentUserChatsConnectionReturn => {
  const { result, loading } = useQuery(
    CurrentUserChatsDocument,
    () => ({
      chatFilters: { unread: false },
      before: before?.value,
      after: after?.value,
      ...(before?.value ? { last: perPage } : { first: perPage }),
    }),
    {},
  )

  const connection = computed(() => result?.value?.currentUser.chats)

  return {
    loading,
    ...useConnectionResult(connection),
  }
}
