<script lang="ts" setup>
import { Temporal } from '@js-temporal/polyfill'
import { isToday } from '@/src/lib/dateTimeUtils'

const props = withDefaults(
  defineProps<{
    value: string
    dateTimeFormat?: string
    timeFormat?: string
  }>(),
  {
    dateTimeFormat: 'YYYY/MM/DD HH:mm',
    timeFormat: 'HH:mm',
  },
)

const date = computed(() => Temporal.PlainDate.from(props.value))
const format = computed(() => (isToday(date.value) ? props.timeFormat : props.dateTimeFormat))
</script>

<template>
  <BaseTime :value="value" :format="format" />
</template>
