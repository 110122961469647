<template>
  <div v-if="isShow" class="masquerade-ribbon" @click="hide">{{ title }}</div>
</template>

<script lang="ts">
import { useBoolean } from '@/src/hooks/useBoolean'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const [isShow, { off: hide }] = useBoolean(true)
    return { isShow, hide }
  },
})
</script>

<style lang="scss" scoped>
@use '@/stylesheets/application/resources';

.masquerade-ribbon {
  background-color: resources.$danger;
  border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
  border-top: dashed 1px rgba(255, 255, 255, 0.65);
  color: resources.$white;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 16px;
  opacity: 0.75;
  padding: 5px 0;
  position: fixed;
  right: -45px;
  text-align: center;
  top: 60px;
  transform: rotate(45deg);
  width: 250px;
  z-index: 10000;
}
</style>
