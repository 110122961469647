<template>
  <div class="notification-item-content">
    <div class="notification-item-content__title">
      <slot />
      <DateTimeOrTime class="has-text-grey" :value="createdAt" date-time-format="MM/DD HH:mm" />
    </div>
    <div class="notification-item-content__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    createdAt: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.notification-item-content {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding-right: 10px;
  width: 230px;
}

.notification-item-content__title {
  margin-bottom: 2px;
}

.notification-item-content__body {
  height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
}
</style>
