<script lang="ts" setup>
import { Temporal } from '@js-temporal/polyfill'
import { useDateFormat } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    value: string
    format?: string
  }>(),
  {
    format: 'YYYY/MM/DD HH:mm',
  },
)

const legacyDate = computed(() => {
  // NOTE: どの環境でも日本時間での表示となるように、あえて日時はそのままでタイムゾーンのみをブラウザ環境のタイムゾーンに変更している
  const dateTime = Temporal.PlainDateTime.from(props.value).toZonedDateTime(Temporal.Now.timeZoneId())
  return new Date(dateTime.epochMilliseconds)
})

const machineReadable = useDateFormat(legacyDate, 'YYYY-MM-DDTHH:mm:ssXXX')
const humanReadable = useDateFormat(legacyDate, toRef(props, 'format'), { locales: 'ja-JP' })
const title = useDateFormat(legacyDate, 'YYYY-MM-DD HH:mm')
</script>

<template>
  <time :dateTime="machineReadable" :title="title">{{ humanReadable }}</time>
</template>
