export const hiraToKana = (str: string): string => {
  // eslint-disable-next-line unicorn/prefer-code-point
  return str.replaceAll(/[\u3041-\u3096]/g, (ch) => String.fromCharCode(ch.charCodeAt(0) + 0x60))
}

export const normalizeSearchString = (str: string): string => {
  return hiraToKana(str.normalize('NFKC')).toLowerCase()
}

export const truncate = (str: string, maxLength: number): string => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str
}
