<template>
  <Teleport to="#teleport-target-page-title">
    <TheUserMenu :id="id" :name="name" :avatar-path="avatarPath" :loading="loading" :users="users" :on-show="load" />
  </Teleport>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable'
import type { UserForUserMenuFragment } from '@/src/graphql/operations'
import { CurrentUserTeammatesForUserMenuDocument } from '@/src/graphql/operations'
import { getApolloClient } from '@/src/lib/apollo'

gql`
  query currentUserTeammatesForUserMenu {
    currentUser {
      id
      teammates {
        id
        ...userForUserMenu
      }
    }
  }
`

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    avatarPath: {
      type: String,
      required: true,
    },
  },
  setup() {
    provideApolloClient(getApolloClient())

    const { result, loading, load } = useLazyQuery(CurrentUserTeammatesForUserMenuDocument)
    const users = computed<readonly UserForUserMenuFragment[]>(() => result?.value?.currentUser.teammates ?? [])

    return { load, loading, users }
  },
})
</script>
