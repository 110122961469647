<script lang="ts" setup>
defineProps<{
  variant: 'danger' | 'success' | 'info'
}>()

const isOpen = ref(true)
</script>

<template>
  <div
    v-show="isOpen"
    class="footer-banner has-text-white"
    :class="`has-background-${variant}`"
    data-test-selector="footer-banner"
  >
    <button
      class="delete tw-absolute tw-top-0 tw-right-0 mt-2 mr-2"
      data-test-selector="footer-banner__delete"
      @click="isOpen = false"
    />
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '@/stylesheets/application/resources';

.footer-banner {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  opacity: 0.8;
  padding: 2rem;
  position: fixed;
  width: 100%;
  z-index: 10;

  @include resources.mobile {
    height: auto;
    text-align: center;
  }
}
</style>
