import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import { computed } from 'vue'
import type { CreatePlannedWorkMutationVariables, UpdatePlannedWorkMutationVariables } from '@/src/graphql/operations'
import {
  CreatePlannedWorkDocument,
  DailyPlannedWorksForTheScheduleTimelineDocument,
  DeletePlannedWorkDocument,
  StartPlanDocument,
  UpdatePlannedWorkDocument,
} from '@/src/graphql/operations'
import { validateMutationResult } from '@/src/lib/apolloUtils'
import { isSamePlannedWork } from '@/src/lib/dateTimeUtils'
import type { LegacyWorkable } from '@/src/lib/workableUtils'
import { toLegacyWorkable } from '@/src/lib/workableUtils'

gql`
  fragment plannedWorkForScheduleTimeline on PlannedWork {
    id
    userId
    to
    from
    note
    taskTemplateId
    taskTemplate {
      id
      ...workableTaskTemplate
    }
  }

  query dailyPlannedWorksForTheScheduleTimeline($date: ISO8601Date!) {
    currentUser {
      id
      mainTeamMember {
        id
        intervalEndTime(date: $date)
      }
      dailyPlannedWorks(date: $date) {
        id
        ...plannedWorkForScheduleTimeline
      }
      recurrencePlannedWorks(date: $date) {
        id
        to
        from
        taskTemplateId
      }
    }
  }

  mutation CreatePlannedWork($from: ISO8601DateTime!, $to: ISO8601DateTime!, $note: String!, $taskTemplateId: ID!) {
    createPlannedWork(input: { from: $from, to: $to, note: $note, taskTemplateId: $taskTemplateId }) {
      clientMutationId
      result {
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  mutation DeletePlannedWork($id: ID!) {
    deletePlannedWork(input: { id: $id }) {
      clientMutationId
    }
  }

  mutation UpdatePlannedWork(
    $id: ID!
    $from: ISO8601DateTime!
    $to: ISO8601DateTime!
    $note: String!
    $taskTemplateId: ID!
  ) {
    updatePlannedWork(input: { id: $id, from: $from, to: $to, note: $note, taskTemplateId: $taskTemplateId }) {
      clientMutationId
      errors {
        message
      }
      plannedWork {
        id
        ...plannedWorkForScheduleTimeline
      }
    }
  }

  mutation startPlan($input: StartPlanInput!) {
    startPlan(input: $input) {
      result {
        ... on Plan {
          id
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }
`

type LegacyPlannedWork = LegacyWorkable & {
  taskTemplateId: string
  note: string
}

type CreatePlannedWork = (variables: CreatePlannedWorkMutationVariables) => Promise<boolean>

export type UpdatePlannedWork = (variables: UpdatePlannedWorkMutationVariables) => Promise<boolean>

type DeletePlannedWork = (id: string) => Promise<boolean>
type StartPlan = (content: string, remoteWorking: boolean) => Promise<boolean>

type UseDailyPlannedWorksReturn = {
  plannedWorks: Ref<readonly LegacyPlannedWork[]>
  plannedWorksWithOutRecurrence: Ref<readonly LegacyPlannedWork[]>
  plannedWorksOnlyRecurrence: Ref<readonly LegacyPlannedWork[]>
  createPlannedWork: CreatePlannedWork
  updatePlannedWork: UpdatePlannedWork
  deletePlannedWork: DeletePlannedWork
  startPlan: StartPlan
  intervalEndTime: Ref<string | undefined>
}

// TODO: GraphQL導入前の型に無理やり合わせている関係で色々辛いが、全部終わったらリファクタする
export const useDailyPlannedWorks = (date: Ref<string>): UseDailyPlannedWorksReturn => {
  const { result, refetch } = useQuery(DailyPlannedWorksForTheScheduleTimelineDocument, { date: date.value })

  const plannedWorks = computed<readonly LegacyPlannedWork[]>(
    () =>
      result?.value?.currentUser.dailyPlannedWorks.map((_) => ({
        ...toLegacyWorkable(_),
        taskTemplateId: _.taskTemplateId,
        note: _.note,
      })) ?? [],
  )

  const recurrencePlannedWorks = computed(() => result?.value?.currentUser.recurrencePlannedWorks ?? [])

  const intervalEndTime = computed(() => result?.value?.currentUser.mainTeamMember.intervalEndTime)

  const plannedWorksWithOutRecurrence = computed<readonly LegacyPlannedWork[]>(() => {
    // NOTE: 繰り返し予定を除外
    return plannedWorks.value.filter(
      (plannedWork) =>
        !recurrencePlannedWorks.value.some((recurrencePlannedWork) =>
          isSamePlannedWork(plannedWork, recurrencePlannedWork),
        ),
    )
  })

  const plannedWorksOnlyRecurrence = computed<readonly LegacyPlannedWork[]>(() => {
    // NOTE: 繰り返し予定として登録された予定のみを抽出
    return plannedWorks.value.filter((plannedWork) =>
      recurrencePlannedWorks.value.some((recurrencePlannedWork) =>
        isSamePlannedWork(plannedWork, recurrencePlannedWork),
      ),
    )
  })

  const { mutate: mutateCreatePlannedWork } = useMutation(CreatePlannedWorkDocument)

  const createPlannedWork: CreatePlannedWork = async (variables) => {
    const mutationResult = await mutateCreatePlannedWork(variables)
    const success = validateMutationResult(mutationResult?.data?.createPlannedWork?.result)
    if (success) refetch()
    return success
  }

  const { mutate: mutateUpdatePlannedWork } = useMutation(UpdatePlannedWorkDocument)

  const updatePlannedWork: UpdatePlannedWork = async (variables) => {
    const mutationResult = await mutateUpdatePlannedWork(variables)

    return validateMutationResult(mutationResult?.data?.updatePlannedWork)
  }

  const { mutate: mutateDeletePlannedWork } = useMutation(DeletePlannedWorkDocument)
  const deletePlannedWork: DeletePlannedWork = async (id) => {
    await mutateDeletePlannedWork({ id })
    refetch()
    return true
  }

  const { mutate: mutateStartPlan } = useMutation(StartPlanDocument)
  const startPlan: StartPlan = async (content, remoteWorking) => {
    const input = { date: date.value, content, remoteWorking }
    const mutationResult = await mutateStartPlan({ input })
    return validateMutationResult(mutationResult?.data?.startPlan?.result)
  }

  return {
    plannedWorks,
    plannedWorksWithOutRecurrence,
    plannedWorksOnlyRecurrence,
    createPlannedWork,
    deletePlannedWork,
    updatePlannedWork,
    startPlan,
    intervalEndTime,
  }
}
