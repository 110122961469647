<template>
  <LatestReleaseNote v-if="releaseNote && isShow" :release-note="releaseNote" :on-close="handleClose" />
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@vue/apollo-composable'
import { useCssVar, useLocalStorage } from '@vueuse/core'
import { LatestReleaseNoteDocument } from '@/src/graphql/operations'
import { JST_TIMEZONE } from '@/src/lib/dateTimeUtils'

gql`
  query latestReleaseNote {
    latestReleaseNote {
      id
      title
      url
      createdAt
    }
  }
`

const NEW_DAYS = 30

export default defineComponent({
  setup() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    const closedId = useLocalStorage<string | undefined>('latest-release-note-closed-id', undefined)
    // NOTE: css変数使うにしてもjsから書き換える必要なかったかも！？
    // SEE:https://github.com/SonicGarden/wlb-morning-mail/pull/2001#discussion_r805511345
    const height = useCssVar('--latest-release-note-height')

    const { result } = useQuery(LatestReleaseNoteDocument)
    const releaseNote = computed(() => result?.value?.latestReleaseNote)
    const isShow = computed(() => {
      if (!releaseNote.value) return false
      if (releaseNote.value.id === closedId.value) return false

      const createdAt = Temporal.PlainDateTime.from(releaseNote.value.createdAt)
      const expiredAt = Temporal.Now.plainDateTimeISO(JST_TIMEZONE).subtract({ days: NEW_DAYS })

      return Temporal.PlainDateTime.compare(createdAt, expiredAt) === 1
    })

    const handleClose = () => {
      if (!releaseNote.value) return

      closedId.value = releaseNote.value.id
    }

    watch(isShow, (value) => {
      height.value = value ? '40px' : '0px'
    })

    return { releaseNote, isShow, handleClose }
  },
})
</script>
