import { set, clear } from 'badgin'
import type { Ref } from 'vue'
import { onBeforeUnmount, watch } from 'vue'

export const useBadgin = (count: Ref<number>): void => {
  watch(count, () => {
    try {
      if (count.value === 0) {
        clear()
        return
      }

      set(count.value, {
        favicon: {
          backgroundColor: '#b01f24',
        },
      })
    } catch {
      // NOTE: ブラウザによってはdevicePixelRatioListenerがundefinedとなってエラーが出る不具合の抑制（握りつぶして実害はない）
    }
  })

  onBeforeUnmount(() => {
    try {
      clear()
    } catch {
      // NOTE: ブラウザによってはdevicePixelRatioListenerがundefinedとなってエラーが出る不具合の抑制（握りつぶして実害はない）
    }
  })
}
