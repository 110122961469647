<template>
  <Teleport to="#teleport-target-page-title">
    <TheTeamMenu :id="id" :name="name" :loading="loading" :teams="teams" :on-show-team-dropdown-menu="load" />
  </Teleport>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable'
import type { TeamForTeamDropdownMenuFragment } from '@/src/graphql/operations'
import { CurrentUserTeamsForTeamMenuDocument } from '@/src/graphql/operations'
import { getApolloClient } from '@/src/lib/apollo'

gql`
  query currentUserTeamsForTeamMenu {
    currentUser {
      id
      teams {
        id
        ...teamForTeamDropdownMenu
      }
    }
  }
`

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    provideApolloClient(getApolloClient())

    const { result, loading, load } = useLazyQuery(CurrentUserTeamsForTeamMenuDocument)
    const teams = computed<readonly TeamForTeamDropdownMenuFragment[]>(() => result?.value?.currentUser.teams ?? [])

    return { load, loading, teams }
  },
})
</script>
