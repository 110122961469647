import type { BrowserLocationState } from '@vueuse/core'
import { assertIsDefined } from '@/src/lib/typeUtils'

/** 現在のlocationを元にベースとなるIDのみを変更したURLを生成する関数 */
export const changeParentIdInLocation = (
  location: BrowserLocationState,
  id: string,
  passedHashKeys: readonly string[] = [],
): string => {
  const { pathname, search, hash } = location

  assertIsDefined(pathname)
  assertIsDefined(hash)

  const newPathname = pathname.replace(/\/(\d+)/, `/${id}`)
  const newHash = hash.includes('?') ? filterHash(hash, passedHashKeys) : hash
  return [newPathname, search, newHash].join('')
}

const filterHash = (hash: string, passedKeys: readonly string[]): string => {
  const [path, query] = hash.split('?')
  const searchParams = new URLSearchParams(query)
  const deleteKeys = [...searchParams.keys()].filter((key) => !passedKeys.includes(key))
  for (const deleteKey of deleteKeys) {
    searchParams.delete(deleteKey)
  }
  const newQuery = searchParams.toString()
  return [path, newQuery].join('?')
}
