import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import type { CreateDoneWorkInput, PlanForDoneScheduleTimelineFragment } from '@/src/graphql/operations'
import {
  CreateDoneWorkDocument,
  DailyDoneWorksForDoneScheduleTimelineDocument,
  DeleteDoneWorkDocument,
  FinishPlanDocument,
  UpdateDoneWorkDocument,
} from '@/src/graphql/operations'
import type { UpdateDoneWorkInput } from '@/src/graphql/types'
import { validateMutationResult } from '@/src/lib/apolloUtils'
import { assertIsDefined } from '@/src/lib/typeUtils'
import type { LegacyWorkable } from '@/src/lib/workableUtils'
import { toLegacyWorkable } from '@/src/lib/workableUtils'

gql`
  fragment plannedWorkForDoneScheduleTimeline on PlannedWork {
    id
    userId
    to
    from
    note
    taskTemplateId
    taskTemplate {
      id
      ...workableTaskTemplate
    }
  }

  fragment planForDoneScheduleTimeline on Plan {
    id
    finished
    startMessage {
      id
      content
    }
    finishMessage {
      id
      content
      chat {
        id
        chatMessagesCount
      }
    }
  }

  fragment doneWorkForDoneScheduleTimeline on DoneWork {
    id
    userId
    to
    from
    note
    burst
    taskTemplateId
    taskTemplate {
      id
      ...workableTaskTemplate
    }
  }

  query dailyDoneWorksForDoneScheduleTimeline($date: ISO8601Date!) {
    currentUser {
      id
      mainTeamMember {
        id
        intervalEndTime(date: $date)
      }
      dailyPlan(date: $date) {
        id
        ...planForDoneScheduleTimeline
      }
      dailyPlannedWorks(date: $date) {
        id
        ...plannedWorkForDoneScheduleTimeline
      }
      dailyDoneWorks(date: $date) {
        id
        ...doneWorkForDoneScheduleTimeline
      }
    }
  }

  mutation CreateDoneWork($input: CreateDoneWorkInput!) {
    createDoneWork(input: $input) {
      result {
        ... on DoneWork {
          id
          ...doneWorkForDoneScheduleTimeline
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  mutation DeleteDoneWork($id: ID!) {
    deleteDoneWork(input: { id: $id }) {
      result {
        ... on User {
          id
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  mutation UpdateDoneWork($input: UpdateDoneWorkInput!) {
    updateDoneWork(input: $input) {
      result {
        ... on DoneWork {
          id
          ...doneWorkForDoneScheduleTimeline
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  mutation FinishPlan($input: FinishPlanInput!) {
    finishPlan(input: $input) {
      result {
        ... on Plan {
          id
          ...planForDoneScheduleTimeline
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }
`

export type LegacyPlannedWork = LegacyWorkable & {
  taskTemplateId: string
  note: string
}

export type LegacyDoneWork = LegacyWorkable & {
  taskTemplateId: string
  note: string
  burst: boolean
}

type CreateDoneWork = (input: CreateDoneWorkInput) => Promise<boolean>

export type UpdateDoneWork = (input: UpdateDoneWorkInput) => Promise<boolean>

type DeleteDoneWork = (id: string) => Promise<boolean>
type FinishPlan = (content: string) => Promise<boolean>

type UseDailyDoneWorksReturn = {
  plannedWorks: Ref<readonly LegacyPlannedWork[]>
  doneWorks: Ref<readonly LegacyDoneWork[]>
  createDoneWork: CreateDoneWork
  updateDoneWork: UpdateDoneWork
  deleteDoneWork: DeleteDoneWork
  finishPlan: FinishPlan
  intervalEndTime: Ref<string | undefined>
  plan: Ref<PlanForDoneScheduleTimelineFragment | undefined>
  loading: Ref<boolean>
}

// TODO: GraphQL導入前の型に無理やり合わせている関係で色々辛いが、全部終わったらリファクタする
export const useDailyDoneWorks = (date: Ref<string>): UseDailyDoneWorksReturn => {
  const { result, refetch, loading } = useQuery(DailyDoneWorksForDoneScheduleTimelineDocument, { date: date.value })

  const plannedWorks = computed<readonly LegacyPlannedWork[]>(
    () =>
      result?.value?.currentUser.dailyPlannedWorks.map((plannedWork) => ({
        ...toLegacyWorkable(plannedWork),
        taskTemplateId: plannedWork.taskTemplateId,
        note: plannedWork.note,
      })) ?? [],
  )

  const doneWorks = computed<readonly LegacyDoneWork[]>(
    () =>
      result?.value?.currentUser.dailyDoneWorks.map((doneWork) => ({
        ...toLegacyWorkable(doneWork),
        taskTemplateId: doneWork.taskTemplateId,
        note: doneWork.note,
        burst: doneWork.burst,
      })) ?? [],
  )

  const plan = computed(() => result?.value?.currentUser.dailyPlan)
  const intervalEndTime = computed(() => result?.value?.currentUser.mainTeamMember.intervalEndTime)

  const { mutate: mutateCreateDoneWork } = useMutation(CreateDoneWorkDocument)

  const createDoneWork: CreateDoneWork = async (input) => {
    const mutationResult = await mutateCreateDoneWork({ input })
    const success = validateMutationResult(mutationResult?.data?.createDoneWork?.result)
    if (success) refetch()
    return success
  }

  const { mutate: mutateUpdateDoneWork } = useMutation(UpdateDoneWorkDocument)

  const updateDoneWork: UpdateDoneWork = async (dirtyInput) => {
    const { id, from, to, note, taskTemplateId, burst } = dirtyInput
    const input = { id, from, to, note, taskTemplateId, burst }
    const mutationResult = await mutateUpdateDoneWork({ input })

    return validateMutationResult(mutationResult?.data?.updateDoneWork?.result)
  }

  const { mutate: mutateDeleteDoneWork } = useMutation(DeleteDoneWorkDocument)
  const deleteDoneWork: DeleteDoneWork = async (id) => {
    const mutationResult = await mutateDeleteDoneWork({ id })
    const success = validateMutationResult(mutationResult?.data?.deleteDoneWork?.result)
    if (success) {
      refetch()
    }
    return success
  }

  const { mutate: mutateFinishPlan } = useMutation(FinishPlanDocument)
  const finishPlan: FinishPlan = async (content) => {
    assertIsDefined(plan.value)
    const input = { id: plan.value.id, content }
    const mutationResult = await mutateFinishPlan({ input })
    return validateMutationResult(mutationResult?.data?.finishPlan?.result)
  }

  return {
    plannedWorks,
    doneWorks,
    createDoneWork,
    deleteDoneWork,
    updateDoneWork,
    finishPlan,
    intervalEndTime,
    plan,
    loading,
  }
}
