import { showErrors } from './toast'
import type { ValidationError } from '@/src/graphql/types'

type MutationResult = {
  __typename: string
  errors?: ReadonlyArray<Pick<ValidationError, 'message'>>
}

export const validateMutationResult = (result?: MutationResult): boolean => {
  if (!result) return false

  if (result.errors) {
    const messages = result.errors.map(({ message }) => message)
    showErrors(messages)
    return false
  }

  return true
}
