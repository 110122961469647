import Rails from '@rails/ujs'
import '@sonicgarden/autosize-textarea-element'
import { initVue } from './initVue'

export const initApplication = (): void => {
  // NOTE: vite-dev環境だと明示的に呼ばないとRails.start()が実行されないため
  if (!window._rails_loaded) {
    Rails.start()
  }
  initVue()
}
