<script lang="ts">
import { gql } from '@apollo/client/core'

type TaskTemplate = TaskTemplateForTaskTemplateSelectFragment

gql`
  fragment taskTemplateForTaskTemplateSelect on TaskTemplate {
    id
    title
    team {
      id
      name
    }
    companyLargeCategory {
      id
      name
    }
  }
`
</script>

<script lang="ts" setup>
import VueSelect from 'vue-select'
import type { Filter } from './VSelect.vue'
import type { TaskTemplateForTaskTemplateSelectFragment } from '@/src/graphql/operations'

const filterSearch: Filter<TaskTemplate> = (options, search) => {
  const loweredSearch = search.toLowerCase()
  return options.filter(({ team, companyLargeCategory, title }) =>
    [team.name, companyLargeCategory.name, title].some((_title) => _title.toLowerCase().includes(loweredSearch)),
  )
}

const props = withDefaults(
  defineProps<{
    modelValue?: string
    taskTemplates: readonly TaskTemplate[]
  }>(),
  {
    value: '',
    taskTemplates: () => [],
  },
)

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const selectedTaskTemplate = ref<TaskTemplate>()
const inputRef = ref<HTMLInputElement>()

watch(selectedTaskTemplate, (newValue) => {
  emit('update:modelValue', newValue?.id ?? '')
})

watch(
  () => props.taskTemplates,
  (newTaskTemplates) => {
    selectedTaskTemplate.value = newTaskTemplates.find((taskTemplate) => taskTemplate.id === props.modelValue)
  },
  { immediate: true },
)

defineExpose({
  focus: () => inputRef.value?.focus(),
})
</script>

<template>
  <VueSelect
    ref="select"
    v-model="selectedTaskTemplate"
    class="task-template-select"
    label="title"
    :options="taskTemplates"
    :filter="filterSearch"
    placeholder="文字入力で絞り込めます"
    v-bind="$attrs"
  >
    <!-- SEE: https://vue-select.org/guide/validation.html -->
    <template #search="{ attributes, events }">
      <input
        v-bind="attributes"
        ref="inputRef"
        class="vs__search"
        :required="!selectedTaskTemplate"
        data-test-selector="v-select-input"
        v-on="events"
      />
    </template>
    <template #no-options>
      <div class="has-text-grey">一致する選択肢はありません。</div>
    </template>
    <template #selected-option="option">
      <span :title="option.title">
        {{ option.title }}
      </span>
    </template>
    <template #option="option">
      <div :title="option.title" class="task-template-select__title is-size-6">
        {{ option.title }}
      </div>
      <div class="task-template-select__sub-title is-size-7">
        {{ option.team.name }}
        &gt;
        {{ option.companyLargeCategory.name }}
      </div>
    </template>
  </VueSelect>
</template>

<style lang="scss" scoped>
@use '@/stylesheets/application/resources';

.task-template-select {
  // NOTE: 選択済みの中身が長い場合に表示が崩れないように
  // TODO: 出来ればVSelectコンポーネント側で対応したい
  :deep(.vs__selected) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 430px;

    @include resources.mobile {
      width: 260px;
    }
  }

  :deep(.vs__dropdown-option) {
    .task-template-select__title,
    .task-template-select__sub-title {
      white-space: normal;
    }

    &:not(.vs__dropdown-option--highlight) {
      .task-template-select__sub-title {
        color: resources.$grey;
      }
    }
  }
}
</style>
