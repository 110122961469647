import type { NormalizedCacheObject, Operation } from '@apollo/client/core'
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { createConsumer } from '@rails/actioncable'
import Rails from '@rails/ujs'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { createErrorLink } from './apolloCreateErrorLink'
import generatedIntrospection from '@/src/graphql/introspectionResult'

const hasSubscriptionOperation = ({ query: { definitions } }: Operation): boolean => {
  return definitions.some(
    (definition) => definition.kind === 'OperationDefinition' && definition.operation === 'subscription',
  )
}

interface GetApolloClient {
  (): ApolloClient<NormalizedCacheObject>
  client?: ApolloClient<NormalizedCacheObject>
}

export const getApolloClient: GetApolloClient = () => {
  if (getApolloClient.client) {
    return getApolloClient.client
  }

  const cable = createConsumer()

  const httpLink = new BatchHttpLink({
    uri: '/graphql',
    batchMax: 50,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-CSRF-TOKEN': Rails.csrfToken(),
      },
    }
  })

  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    // NOTE: レコード削除時の警告抑制
    // SEE: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
    typePolicies: {
      Chat: {
        fields: {
          chatMessages: {
            merge: false,
          },
        },
      },
      ChatMessage: {
        fields: {
          likes: {
            merge: false,
          },
        },
      },
      User: {
        fields: {
          dailyPlannedWorks: {
            merge: false,
          },
          dailyDoneWorks: {
            merge: false,
          },
        },
      },
    },
  })

  const link = split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    ApolloLink.from([createErrorLink(), authLink, httpLink]),
  )

  // Create the apollo client
  getApolloClient.client = new ApolloClient({
    link,
    cache,
  })

  return getApolloClient.client
}
