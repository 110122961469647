<template>
  <div>
    <div v-if="isMobile">
      <div @click="handleClick">
        <slot />
      </div>
      <Teleport to="body">
        <BModal v-model="dirtyShown">
          <div class="dropdown-content mx-4" :class="{ 'p-0': paddingless }">
            <slot name="popper" />
          </div>
        </BModal>
      </Teleport>
    </div>
    <!-- eslint-disable-next-line vue/no-v-model-argument -->
    <Dropdown v-else v-model:shown="dirtyShown" :disabled="disabled" theme="dropdown" :triggers="triggers">
      <slot />
      <template #popper>
        <!-- NOTE: bulmaのdropdownデザインを活用するようにしている -->
        <div class="tw-min-w-48" :class="{ 'sm:tw-max-w-xs': !paddingless }">
          <div class="dropdown-content" :class="{ 'py-0': paddingless }">
            <slot name="popper" />
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import type { TriggerEvent } from 'floating-vue'
import { Dropdown } from 'floating-vue'
import type { PropType } from 'vue'
import { useBoolean } from '@/src/hooks/useBoolean'
import { useMobileScreen } from '@/src/hooks/useMobileScreen'

export default defineComponent({
  components: { Dropdown },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    shown: {
      type: Boolean,
      default: false,
    },
    triggers: {
      type: Array as PropType<TriggerEvent[]>,
    },
  },
  emits: {
    'update:shown': (_value: boolean) => true,
    show: () => true,
    hide: () => true,
  },
  setup(props, context) {
    const isMobile = useMobileScreen()
    const [dirtyShown, setDirtyShown] = useBoolean(props.shown)

    watch(
      () => props.shown,
      (newValue) => {
        dirtyShown.value = newValue
      },
    )

    watch(dirtyShown, (newValue) => {
      context.emit('update:shown', newValue)
      newValue ? context.emit('show') : context.emit('hide')
    })

    const handleClick = () => {
      if (props.disabled) return

      setDirtyShown.on()
    }

    return { isMobile, dirtyShown, handleClick }
  },
})
</script>
