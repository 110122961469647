<script lang="ts" setup>
import { gql } from '@apollo/client/core'
import type { ReleaseNoteForLatestReleaseNoteFragment } from '@/src/graphql/operations'

gql`
  fragment releaseNoteForLatestReleaseNote on ReleaseNote {
    id
    title
    url
    createdAt
  }
`

type OnClose = () => void

defineProps<{
  releaseNote: ReleaseNoteForLatestReleaseNoteFragment
  onClose: OnClose
}>()
</script>

<template>
  <div class="has-background-warning py-2 px-3 tw-relative">
    <a :href="releaseNote.url" target="_blank" class="has-text-dark tw-text-sm tw-space-x-2">
      <BaseTime :value="releaseNote.createdAt" format="YYYY/MM/DD" />
      <span class="is-underlined">{{ releaseNote.title }}</span>
    </a>
    <button class="delete tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-right-0 mr-2" @click="onClose" />
  </div>
</template>
