/* eslint-disable unicorn/no-await-expression-member */
import { vwrap } from '@sonicgarden/vwrap'
import Snackbar from 'buefy/dist/esm/snackbar'
import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createWebHashHistory } from 'vue-router'
import ColorIconify from '@/src/basics/ColorIconify.vue'
import UserAvatar from '@/src/basics/UserAvatar.vue'
import { NotPlannedBanner, FooterBanner } from '@/src/components'
import AdminModeBanner from '@/src/components/AdminModeBanner.vue'
import HelpTooltip from '@/src/components/HelpTooltip.vue'
import IconTooltip from '@/src/components/IconTooltip.vue'
import MasqueradeRibbon from '@/src/components/MasqueradeRibbon.vue'
import PrintButton from '@/src/components/PrintButton.vue'
import ToastPortal from '@/src/components/ToastPortal.vue'
import VDropdown from '@/src/components/VDropdown.vue'
import LatestReleaseNoteContainer from '@/src/containers/LatestReleaseNoteContainer.vue'
import TeamMenuContainer from '@/src/containers/TeamMenuContainer.vue'
import TheNavBar from '@/src/containers/TheNavBar.vue'
import UserMenuContainer from '@/src/containers/UserMenuContainer.vue'
import { createRouterPatched } from '@/src/lib/createRouterPatched'
import { displayFlashMessage } from '@/src/lib/displayFlashMessage'

const beforeMountCallback = (app: App) => {
  window.bugsnagClient?.getPlugin('vue')?.install(app)

  app.use(Snackbar)
}

export const initVue = (): void => {
  vwrap('x-the-chat-notifications', async () => (await import('@/src/pages/TheChatNotifications.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-the-notifications', async () => (await import('@/src/pages/TheNotifications.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-user-avatar', UserAvatar, {
    beforeMountCallback,
  })
  vwrap('x-toast-portal', ToastPortal, {
    beforeMountCallback,
  })
  vwrap('x-ribbon', MasqueradeRibbon, {
    beforeMountCallback,
  })
  vwrap('x-help-tooltip', HelpTooltip, {
    beforeMountCallback,
  })
  vwrap('x-color-iconify', ColorIconify, {
    beforeMountCallback,
  })
  vwrap('x-icon-tooltip', IconTooltip, {
    beforeMountCallback,
  })
  vwrap('x-the-nav-bar', TheNavBar, {
    beforeMountCallback,
  })
  vwrap('x-the-done-schedule-timeline', async () => (await import('@/src/pages/TheDoneScheduleTimeline.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-company-team-members', async () => (await import('@/src/pages/CompanyTeamMembers.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-company-users', async () => (await import('@/src/pages/CompanyUsers.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-company-teams', async () => (await import('@/src/pages/CompanyTeams.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-static-modal', async () => (await import('@/src/components/StaticModal.vue')).default, {
    hasSlot: true,
    beforeMountCallback,
  })
  vwrap('x-pie-table', async () => (await import('@/src/components/PieTable.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-bar-chart-difference', async () => (await import('@/src/components/BarChartDifference.vue')).default, {
    beforeMountCallback,
  })
  vwrap(
    'x-large-category-detail-table',
    async () => (await import('@/src/components/LargeCategoryDetailTable.vue')).default,
    {
      beforeMountCallback,
    },
  )
  vwrap('x-line-chart', async () => (await import('@/src/components/LineChart.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-column-stacked-chart', async () => (await import('@/src/components/ColumnStackedChart.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-plan-diff-table', async () => (await import('@/src/components/PlanDiffTable.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-team-menu-container', TeamMenuContainer, {
    beforeMountCallback,
  })
  vwrap('x-user-menu-container', UserMenuContainer, {
    beforeMountCallback,
  })
  vwrap('x-admin-mode-banner', AdminModeBanner, {
    beforeMountCallback,
  })
  vwrap('x-not-planned-banner', NotPlannedBanner, {
    beforeMountCallback,
  })
  vwrap('x-the-schedule-timeline', async () => (await import('@/src/pages/TheScheduleTimeline.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-latest-release-note-container', LatestReleaseNoteContainer, {
    beforeMountCallback,
  })
  vwrap('x-print-button', PrintButton, {
    beforeMountCallback,
  })

  vwrap('x-footer-banner', FooterBanner, {
    hasSlot: true,
    beforeMountCallback,
  })
  vwrap('x-delete-confirm-modal', async () => (await import('@/src/components/DeleteConfirmModal.vue')).default, {
    hasSlot: true,
    beforeMountCallback,
  })
  vwrap('x-v-dropdown', VDropdown, {
    hasSlot: true,
    beforeMountCallback,
  })

  vwrap('x-monthly-messages', async () => (await import('@/src/pages/MonthlyMessages.vue')).default, {
    beforeMountCallback: (app) => {
      // TODO: ひとまずエラーの出ないように設定しているがvue-router依存を減らしていきたい
      const routes: RouteRecordRaw[] = [
        {
          path: '/:pathMatch(.*)*',
          component: async () => (await import('@/src/pages/MonthlyMessages.vue')).default,
        },
      ]
      app.use(createRouterPatched({ history: createWebHashHistory(), routes }))
      beforeMountCallback(app)
    },
  })
  vwrap('x-the-user', async () => (await import('@/src/pages/TheUser.vue')).default, {
    beforeMountCallback: (app) => {
      // TODO: ひとまずエラーの出ないように設定しているがvue-router依存を減らしていきたい
      const routes: RouteRecordRaw[] = [
        {
          path: '/:pathMatch(.*)*',
          component: async () => (await import('@/src/pages/TheUser.vue')).default,
        },
      ]
      app.use(createRouterPatched({ history: createWebHashHistory(), routes }))
      beforeMountCallback(app)
    },
  })
  vwrap('x-the-team', async () => (await import('@/src/pages/TheTeam.vue')).default, {
    beforeMountCallback: (app) => {
      const routes: RouteRecordRaw[] = [
        {
          path: '/comments',
          name: 'comments',
          component: async () => (await import('@/src/containers/TheTeamComments.vue')).default,
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'schedules',
          component: async () => (await import('@/src/containers/TheTeamScheduleTimeline.vue')).default,
        },
      ]
      app.use(createRouterPatched({ history: createWebHashHistory(), routes }))
      beforeMountCallback(app)
    },
  })

  // NOTE: form
  vwrap('x-base-file-input', async () => (await import('@/src/components/BaseFileInput.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-input-time', async () => (await import('@/src/components/InputTime.vue')).default, {
    beforeMountCallback,
  })
  vwrap('x-v-select', async () => (await import('@/src/components/VSelect.vue')).default, {
    beforeMountCallback,
  })
  vwrap(
    'x-native-month-input',
    async () => (await import('@/src/components/NativeMonthInput/NativeMonthInput.vue')).default,
    {
      beforeMountCallback,
    },
  )
  vwrap('x-user-select', async () => (await import('@/src/containers/UserSelectContainer.vue')).default, {
    beforeMountCallback,
  })

  document.addEventListener('DOMContentLoaded', () => {
    // NOTE: ガタツキ防止の為に遅延させている
    setTimeout(displayFlashMessage, 300)
  })
}
