<template>
  <NotificationItemContent :created-at="createdAt">
    <div>
      <strong v-text="plan.user.name" />さんが
      <span v-if="notificationKey === 'start_plan'"> 「<strong>今日の予定</strong>」を登録しました </span>
      <span v-else> 「<strong>今日の実績</strong>」を確定しました </span>
    </div>

    <template v-if="messageContent" #body>
      {{ messageContent }}
    </template>
  </NotificationItemContent>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { NotificationRecipientsQuery } from '@/src/graphql/operations'
import type { RelayNode, ExtractUnion } from '@/src/graphql/typeUtil'

type NotificationRecipient = RelayNode<NotificationRecipientsQuery['notificationRecipients']>
type Plan = ExtractUnion<NotificationRecipient['notification']['notifiable'], 'Plan'>

export default defineComponent({
  props: {
    notificationKey: {
      type: String as PropType<'start_plan' | 'finish_plan'>,
      required: true,
    },
    notifiable: {
      type: Object as PropType<Plan>,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const messageContent = computed(() => {
      return props.notificationKey === 'start_plan'
        ? props.notifiable.startMessage?.content
        : props.notifiable.finishMessage?.content
    })

    return {
      messageContent,
      plan: props.notifiable,
    }
  },
})
</script>
