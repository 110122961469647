import { useEventListener } from '@vueuse/core'
import type { Ref } from 'vue'
import { watch } from 'vue'
import { useBoolean } from '@/src/hooks/useBoolean'

export const useExpo = (unreadCount: Ref<number>): void => {
  const [isExpo, { on: enable }] = useBoolean(false)

  watch([isExpo, unreadCount], () => {
    if (!isExpo.value) return

    const data = {
      type: 'updateBadge',
      payload: { count: unreadCount.value },
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(data))
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMessage = ({ data }: any): void => {
    try {
      const { type } = JSON.parse(data)
      if (type === 'initExpo') {
        enable()
      }
    } catch {
      // NOTE: window.postMessageは他でも利用されているのでパース出来ないケースがあるのは仕方ない
    }
  }

  // NOTE: イベント実行順序的にアプリ側からのロード完了通知を受け取ってから初期化する必要がある
  useEventListener(window, 'message', handleMessage)
}
