import type { Router, RouterOptions } from 'vue-router'
import { createRouter } from 'vue-router'

export const createRouterPatched = (options: RouterOptions): Router => {
  const router = createRouter(options)

  // SEE: https://github.com/vuejs/vue-router/issues/1807#issuecomment-336494269
  router.afterEach(() => {
    window.dispatchEvent(new CustomEvent('hashchange'))
  })

  return router
}
