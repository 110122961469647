import type BrowserImageCompression from 'browser-image-compression'

type Options = Parameters<typeof BrowserImageCompression>[1]

const DEFAULT_OPTIONS: Options = {
  maxWidthOrHeight: 2048,
}

export const imageCompression = async (file: File, options: Options = {}): Promise<File> => {
  const { default: browserImageCompression } = await import('browser-image-compression')

  return browserImageCompression(file, {
    ...DEFAULT_OPTIONS,
    ...options,
  })
}

export const isSupportedImageCompression = (file: File): boolean => {
  return ['image/jpeg', 'image/png'].includes(file.type)
}
