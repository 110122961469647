<template>
  <NotificationItemContent :created-at="createdAt">
    <div>
      <strong v-text="chatMessage.user.name" />さんが
      <span>{{ sourceTypeText }}</span>
      「<strong v-text="truncatedTargetDescription" />」であなた宛にコメントしました
    </div>

    <template #body>
      {{ chatMessage.content }}
    </template>
  </NotificationItemContent>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { NotificationRecipientsQuery } from '@/src/graphql/operations'
import type { RelayNode, ExtractUnion } from '@/src/graphql/typeUtil'
import { truncate } from '@/src/lib/stringUtils'

type NotificationRecipient = RelayNode<NotificationRecipientsQuery['notificationRecipients']>
type ChatMessage = ExtractUnion<NotificationRecipient['notification']['notifiable'], 'ChatMessage'>

export default defineComponent({
  props: {
    notifiable: {
      type: Object as PropType<ChatMessage>,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const sourceTypeText = computed(() => props.notifiable.chat.sourceTypeText)
    const description = computed(() => {
      return props.notifiable.chat.source.__typename === 'DoneWork'
        ? props.notifiable.chat.source.taskTemplate.title
        : props.notifiable.chat.source.content
    })
    const truncatedTargetDescription = computed(() => truncate(description.value, 25))

    return {
      truncatedTargetDescription,
      sourceTypeText,
      chatMessage: props.notifiable,
    }
  },
})
</script>
