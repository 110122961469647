<template>
  <ChatHeader :avatar-path="user.avatarPath">
    <UserTooltip>
      <a class="tw-text-xs is-text-ellipsised" :href="userMonthlyMessagesPath({ userId: user.id })">{{ user.name }}</a>
    </UserTooltip>
  </ChatHeader>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import type { PropType } from 'vue'
import type { UserForChatHeaderUserFragment } from '@/src/graphql/operations'
import { userMonthlyMessagesPath } from '@/src/lib/rails-routes'

gql`
  fragment userForChatHeaderUser on User {
    id
    name
    avatarPath
  }
`

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<UserForChatHeaderUserFragment>,
      required: true,
    },
  },
  setup() {
    return { userMonthlyMessagesPath }
  },
})
</script>
