<template>
  <div class="notification-container">
    <div v-if="isLoading" class="is-flex is-justify-content-center py-3">
      <SpinnerIcon />
    </div>
    <template v-else>
      <div v-if="isShowHeader" class="notification-header py-2 tw-text-xs">
        <slot name="header" />
      </div>
      <p v-if="isEmpty" class="notification-empty has-text-grey tw-text-sm">
        <slot name="empty" />
      </p>
      <template v-else>
        <slot />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
    isAlwaysShowHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isShowHeader = computed(() => props.isAlwaysShowHeader || !props.isEmpty)

    return { isShowHeader }
  },
})
</script>

<style lang="scss" scoped>
@use '@/stylesheets/application/resources';

.notification-container {
  min-height: 100px;
  width: resources.$app-notification-container-width;
}

.notification-header {
  border-bottom: 1px solid #eee;
  text-align: center;
  z-index: 2;
}

.notification-empty {
  padding: 0.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
</style>
