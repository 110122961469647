import { BlobUpload } from '@rails/activestorage/src/blob_upload'

// SEE: https://evilmartians.com/chronicles/active-storage-meets-graphql-direct-uploads
export const directUpload = (url: string, headers: Record<string, string>, file: File): Promise<void> => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } })
  return new Promise((resolve, reject) => {
    upload.create((error) => {
      if (error) {
        reject(error)
      } else {
        resolve()
      }
    })
  })
}
