// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (params.hasOwnProperty(param)) {
    if (keys.indexOf(param) === -1) {
      query.push(param + "=" + encodeURIComponent(params[param].toString()));
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function newUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function userSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function destroyUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_out', params, []); }
export function newUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/new', params, []); }
export function editUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/edit', params, []); }
export function userPasswordPath(params?: Record<string, Value>) { return process('/users/password', params, []); }
export function newUserConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation/new', params, []); }
export function userConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation', params, []); }
export function usersConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation', params, []); }
export function teamSettingPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/setting', params, ['teamId']); }
export function teamTaskTemplatesPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/task_templates', params, ['teamId']); }
export function editTeamTaskTemplatePath(params: Params<'teamId' | 'id'>) { return process('/teams/' + params.teamId + '/task_templates/' + params.id + '/edit', params, ['teamId','id']); }
export function teamTaskTemplatePath(params: Params<'teamId' | 'id'>) { return process('/teams/' + params.teamId + '/task_templates/' + params.id + '', params, ['teamId','id']); }
export function newTeamTaskTemplateBulkPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/task_template_bulk/new', params, ['teamId']); }
export function teamTaskTemplateBulkPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/task_template_bulk', params, ['teamId']); }
export function teamTeamMembersPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/team_members', params, ['teamId']); }
export function teamTeamMemberPath(params: Params<'teamId' | 'id'>) { return process('/teams/' + params.teamId + '/team_members/' + params.id + '', params, ['teamId','id']); }
export function teamWorkAnalysisUserPlansPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/user_plans', params, ['teamId']); }
export function teamWorkAnalysisLargeCategoriesPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/large_categories', params, ['teamId']); }
export function teamWorkAnalysisExportsPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/exports', params, ['teamId']); }
export function teamWorkAnalysisPlanDifferencesPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/plan_differences', params, ['teamId']); }
export function teamWorkAnalysisRatiosPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/ratios', params, ['teamId']); }
export function teamWorkAnalysisHopesPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/hopes', params, ['teamId']); }
export function teamWorkAnalysisBreakdownsPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis/breakdowns', params, ['teamId']); }
export function teamWorkAnalysisPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/work_analysis', params, ['teamId']); }
export function teamDoneWorksPath(params: Params<'teamId'>) { return process('/teams/' + params.teamId + '/done_works', params, ['teamId']); }
export function editTeamPath(params: Params<'id'>) { return process('/teams/' + params.id + '/edit', params, ['id']); }
export function teamPath(params: Params<'id'>) { return process('/teams/' + params.id + '', params, ['id']); }
export function editScheduleTimelinePath(params?: Record<string, Value>) { return process('/schedule_timeline/edit', params, []); }
export function myUserAvatarPath(params?: Record<string, Value>) { return process('/my/user/avatar', params, []); }
export function editMyUserPath(params?: Record<string, Value>) { return process('/my/user/edit', params, []); }
export function myUserPath(params?: Record<string, Value>) { return process('/my/user', params, []); }
export function myEmailReconfirmationPath(params?: Record<string, Value>) { return process('/my/email/reconfirmation', params, []); }
export function editMyEmailPath(params?: Record<string, Value>) { return process('/my/email/edit', params, []); }
export function myEmailPath(params?: Record<string, Value>) { return process('/my/email', params, []); }
export function myOverlapedPlanPath(params?: Record<string, Value>) { return process('/my/overlaped_plan', params, []); }
export function myDoneWorksPath(params?: Record<string, Value>) { return process('/my/done_works', params, []); }
export function userWorkAnalysisLargeCategoriesPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/work_analysis/large_categories', params, ['userId']); }
export function userWorkAnalysisPlanDifferencesPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/work_analysis/plan_differences', params, ['userId']); }
export function userWorkAnalysisRatiosPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/work_analysis/ratios', params, ['userId']); }
export function userWorkAnalysisBreakdownsPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/work_analysis/breakdowns', params, ['userId']); }
export function userWorkAnalysisPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/work_analysis', params, ['userId']); }
export function userMonthlyMessagesPath(params: Params<'userId'>) { return process('/users/' + params.userId + '/monthly_messages', params, ['userId']); }
export function userPath(params: Params<'id'>) { return process('/users/' + params.id + '', params, ['id']); }
export function markAllReadNotificationsPath(params?: Record<string, Value>) { return process('/notifications/mark_all_read', params, []); }
export function notificationsPath(params?: Record<string, Value>) { return process('/notifications', params, []); }
export function notificationPath(params: Params<'id'>) { return process('/notifications/' + params.id + '', params, ['id']); }
export function planPath(params: Params<'id'>) { return process('/plans/' + params.id + '', params, ['id']); }
export function recurrencePlannedWorksPath(params?: Record<string, Value>) { return process('/recurrence_planned_works', params, []); }
export function newRecurrencePlannedWorkPath(params?: Record<string, Value>) { return process('/recurrence_planned_works/new', params, []); }
export function editRecurrencePlannedWorkPath(params: Params<'id'>) { return process('/recurrence_planned_works/' + params.id + '/edit', params, ['id']); }
export function recurrencePlannedWorkPath(params: Params<'id'>) { return process('/recurrence_planned_works/' + params.id + '', params, ['id']); }
export function chatPath(params: Params<'id'>) { return process('/chats/' + params.id + '', params, ['id']); }
export function markAllReadMyChatsPath(params?: Record<string, Value>) { return process('/my_chats/mark_all_read', params, []); }
export function myChatsPath(params?: Record<string, Value>) { return process('/my_chats', params, []); }
export function staticPagePath(params: Params<'id'>) { return process('/pages/' + params.id + '', params, ['id']); }
export function editCompaniesSettingPath(params?: Record<string, Value>) { return process('/companies/setting/edit', params, []); }
export function companiesSettingPath(params?: Record<string, Value>) { return process('/companies/setting', params, []); }
export function editCompaniesSecuritySettingPath(params?: Record<string, Value>) { return process('/companies/security_setting/edit', params, []); }
export function companiesSecuritySettingPath(params?: Record<string, Value>) { return process('/companies/security_setting', params, []); }
export function companiesSamlIdpMetadataXmlPath(params?: Record<string, Value>) { return process('/companies/saml_idp_metadata_xml', params, []); }
export function companiesTeamTaskTemplatesPath(params: Params<'teamId'>) { return process('/companies/teams/' + params.teamId + '/task_templates', params, ['teamId']); }
export function companiesTeamTeamMembersPath(params: Params<'teamId'>) { return process('/companies/teams/' + params.teamId + '/team_members', params, ['teamId']); }
export function companiesTeamTeamMemberPath(params: Params<'teamId' | 'id'>) { return process('/companies/teams/' + params.teamId + '/team_members/' + params.id + '', params, ['teamId','id']); }
export function companiesTeamTransfereesPath(params: Params<'teamId'>) { return process('/companies/teams/' + params.teamId + '/transferees', params, ['teamId']); }
export function companiesTeamsPath(params?: Record<string, Value>) { return process('/companies/teams', params, []); }
export function newCompaniesTeamPath(params?: Record<string, Value>) { return process('/companies/teams/new', params, []); }
export function editCompaniesTeamPath(params: Params<'id'>) { return process('/companies/teams/' + params.id + '/edit', params, ['id']); }
export function companiesTeamPath(params: Params<'id'>) { return process('/companies/teams/' + params.id + '', params, ['id']); }
export function editCompaniesDefaultTeamPath(params?: Record<string, Value>) { return process('/companies/default_team/edit', params, []); }
export function companiesDefaultTeamPath(params?: Record<string, Value>) { return process('/companies/default_team', params, []); }
export function newCompaniesTeamBulkPath(params?: Record<string, Value>) { return process('/companies/team_bulk/new', params, []); }
export function companiesTeamBulkPath(params?: Record<string, Value>) { return process('/companies/team_bulk', params, []); }
export function resendConfirmationCompaniesUserPath(params: Params<'id'>) { return process('/companies/users/' + params.id + '/resend_confirmation', params, ['id']); }
export function companiesUsersPath(params?: Record<string, Value>) { return process('/companies/users', params, []); }
export function newCompaniesUserPath(params?: Record<string, Value>) { return process('/companies/users/new', params, []); }
export function editCompaniesUserPath(params: Params<'id'>) { return process('/companies/users/' + params.id + '/edit', params, ['id']); }
export function companiesUserPath(params: Params<'id'>) { return process('/companies/users/' + params.id + '', params, ['id']); }
export function newCompaniesUserBulkPath(params?: Record<string, Value>) { return process('/companies/user_bulk/new', params, []); }
export function companiesUserBulkPath(params?: Record<string, Value>) { return process('/companies/user_bulk', params, []); }
export function confirmationCompaniesUserDeletionBulkPath(params?: Record<string, Value>) { return process('/companies/user_deletion_bulk/confirmation', params, []); }
export function newCompaniesUserDeletionBulkPath(params?: Record<string, Value>) { return process('/companies/user_deletion_bulk/new', params, []); }
export function companiesUserDeletionBulkPath(params?: Record<string, Value>) { return process('/companies/user_deletion_bulk', params, []); }
export function companiesConfirmationPath(params?: Record<string, Value>) { return process('/companies/confirmation', params, []); }
export function editCompaniesTaskTemplatePath(params: Params<'id'>) { return process('/companies/task_templates/' + params.id + '/edit', params, ['id']); }
export function companiesTaskTemplatePath(params: Params<'id'>) { return process('/companies/task_templates/' + params.id + '', params, ['id']); }
export function newCompaniesTaskTemplateBulkPath(params?: Record<string, Value>) { return process('/companies/task_template_bulk/new', params, []); }
export function companiesTaskTemplateBulkPath(params?: Record<string, Value>) { return process('/companies/task_template_bulk', params, []); }
export function newCompaniesTeamTransferImportPath(params?: Record<string, Value>) { return process('/companies/team_transfer_import/new', params, []); }
export function companiesTeamTransferImportPath(params?: Record<string, Value>) { return process('/companies/team_transfer_import', params, []); }
export function newCompaniesStartPath(params?: Record<string, Value>) { return process('/companies/start/new', params, []); }
export function companiesStartPath(params?: Record<string, Value>) { return process('/companies/start', params, []); }
export function companiesWorkAnalysisExportsPath(params?: Record<string, Value>) { return process('/companies/work_analysis/exports', params, []); }
export function companiesWorkAnalysisPlanDifferencesPath(params?: Record<string, Value>) { return process('/companies/work_analysis/plan_differences', params, []); }
export function companiesWorkAnalysisRatiosPath(params?: Record<string, Value>) { return process('/companies/work_analysis/ratios', params, []); }
export function companiesWorkAnalysisBreakdownsPath(params?: Record<string, Value>) { return process('/companies/work_analysis/breakdowns', params, []); }
export function companiesWorkAnalysisPath(params?: Record<string, Value>) { return process('/companies/work_analysis', params, []); }
export function companiesDoneWorksPath(params?: Record<string, Value>) { return process('/companies/done_works', params, []); }
export function companiesPlansPath(params?: Record<string, Value>) { return process('/companies/plans', params, []); }
export function companiesWorkSummariesPath(params?: Record<string, Value>) { return process('/companies/work_summaries', params, []); }
export function companiesCompanyServicePlanPath(params?: Record<string, Value>) { return process('/companies/company_service_plan', params, []); }
export function companiesBulkImportsPath(params?: Record<string, Value>) { return process('/companies/bulk_imports', params, []); }
export function companiesBulkImportPath(params: Params<'id'>) { return process('/companies/bulk_imports/' + params.id + '', params, ['id']); }
export function companiesContactPath(params?: Record<string, Value>) { return process('/companies/contact', params, []); }
export function companiesBouncedEmailUsersPath(params?: Record<string, Value>) { return process('/companies/bounced_email_users', params, []); }
export function companiesBouncedEmailUserPath(params: Params<'id'>) { return process('/companies/bounced_email_users/' + params.id + '', params, ['id']); }
export function companiesLoginActivitiesPath(params?: Record<string, Value>) { return process('/companies/login_activities', params, []); }
export function companiesCompanyLargeCategoriesPath(params?: Record<string, Value>) { return process('/companies/company_large_categories', params, []); }
export function newCompaniesCompanyLargeCategoryPath(params?: Record<string, Value>) { return process('/companies/company_large_categories/new', params, []); }
export function editCompaniesCompanyLargeCategoryPath(params: Params<'id'>) { return process('/companies/company_large_categories/' + params.id + '/edit', params, ['id']); }
export function companiesCompanyLargeCategoryPath(params: Params<'id'>) { return process('/companies/company_large_categories/' + params.id + '', params, ['id']); }
