import { FileChecksum } from '@rails/activestorage/src/file_checksum'

function calculateChecksum(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error)
        return
      }

      resolve(checksum)
    })
  })
}

type GetFileMetadataReturn = {
  checksum: string
  filename: string
  contentType: string
  byteSize: number
}

// SEE: https://evilmartians.com/chronicles/active-storage-meets-graphql-direct-uploads
export const getFileMetadata = (file: File): Promise<GetFileMetadataReturn> => {
  return new Promise((resolve) => {
    calculateChecksum(file).then((checksum) => {
      resolve({
        checksum,
        filename: file.name,
        contentType: file.type,
        byteSize: file.size,
      })
    })
  })
}
