<template>
  <IconTooltip :label="description" :description="description" icon-name="bi:question-circle" />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    description: {
      type: String,
      required: true,
    },
  },
})
</script>
