import { useQuery } from '@vue/apollo-composable'
import type { ComputedRef, Ref } from 'vue'
import { SubscribeNotificationWasCreatedDocument, UnreadNotificationsCountDocument } from '@/src/graphql/operations'

export const useUnreadNotificationCount = (userId: Ref<string>): ComputedRef<number> => {
  const { result, subscribeToMore } = useQuery(UnreadNotificationsCountDocument)

  subscribeToMore(() => ({
    document: SubscribeNotificationWasCreatedDocument,
    variables: {
      userId: userId.value,
    },
  }))

  return computed(() => result?.value?.currentUser.unreadNotificationsCount ?? 0)
}
