import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type { Connection } from '@/src/graphql/typeUtil'

export type UseConnectionResultReturn<TNode> = {
  totalCount: ComputedRef<number>
  startCursor: ComputedRef<string | undefined>
  endCursor: ComputedRef<string | undefined>
  nodes: ComputedRef<readonly TNode[]>
}

export const useConnectionResult = <TNode>(
  connection: ComputedRef<Connection<TNode> | undefined>,
): UseConnectionResultReturn<TNode> => {
  const totalCount = computed<number>(() => connection.value?.totalCount ?? 0)
  const startCursor = computed<string | undefined>(() => connection.value?.pageInfo?.startCursor ?? undefined)
  const endCursor = computed<string | undefined>(() => connection.value?.pageInfo?.endCursor ?? undefined)
  const nodes = computed<readonly TNode[]>(() =>
    connection.value?.edges ? connection.value.edges.map((edge) => edge.node) : [],
  )

  return {
    totalCount,
    startCursor,
    endCursor,
    nodes,
  }
}
