<script lang="ts" setup>
import { Temporal } from '@js-temporal/polyfill'
import type { Size } from '@/src/basics/BuButton.vue'
import { usePrintScreen } from '@/src/hooks/usePrintScreen'
import { JST_TIMEZONE } from '@/src/lib/dateTimeUtils'

const props = defineProps<{
  size?: Size
  filename?: string
}>()

const isPrintScreen = usePrintScreen()

const handleClick = () => {
  const origTitle = document.title
  document.title =
    props.filename ??
    [document.title, Temporal.Now.plainDateTimeISO(JST_TIMEZONE).toString({ smallestUnit: 'second' })].join('_')
  window.print()
  document.title = origTitle
}
</script>

<template>
  <!-- NOTE: disabledはEdgeの場合（Chromeだと発生しない）に印刷画面にツールチップだけ表示されてしまう問題への対策 -->
  <VTooltip content="PDFで保存したい場合は印刷ダイアログで「PDF保存」を指定してください。" :disabled="isPrintScreen">
    <BuButton class="print:tw-hidden" :size="size" icon="bi:printer" @click="handleClick">印刷/PDF保存</BuButton>
  </VTooltip>
</template>
