<template>
  <a
    class="notification-box has-text-dark tw-text-xs tw-border-b tw-border-gray-200 hover:tw-bg-gray-100"
    :class="{ 'tw-bg-yellow-50': chat.unread }"
    :href="chatPath({ id: chat.id })"
  >
    <div class="notification-icon">
      <UserAvatar :src="latestMessage.user.avatarPath" type="middle" />
    </div>
    <div class="notification-content tw-tw-space-y-1">
      <div class="tw-tw-space-x-1">
        <span class="name-who has-text-weight-bold" v-text="latestMessage.user.name" />
        <DateTimeOrTime class="has-text-grey" :value="latestMessage.createdAt" date-time-format="MM/DD HH:mm" />
      </div>
      <div>
        {{ chat.sourceTypeText }}
        <strong>「{{ title }}」</strong>
      </div>
      <div class="comment">{{ latestMessage.content }}</div>
    </div>
  </a>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { ChatNode } from '@/src/hooks/useCurrentUserChats'
import { chatPath } from '@/src/lib/rails-routes'
import { truncate } from '@/src/lib/stringUtils'

export type Chat = {
  id: string
  user: TODO<'User'>
  content: string
  createdAt: Date
  sourceTypeText: string
  sourceTitle: string
  unread?: boolean
}

export default defineComponent({
  props: {
    chat: {
      type: Object as PropType<ChatNode>,
      required: true,
    },
  },
  setup(props) {
    const latestMessage = computed(() => {
      if (!props.chat.latestMessage) {
        throw new Error('latestMessage is null')
      }
      return props.chat.latestMessage
    })

    const title = computed<string>(() => {
      if (props.chat.source.__typename === 'DoneWork') {
        return props.chat.source.taskTemplate.title
      }

      return truncate(props.chat.source.content, 15)
    })

    return { latestMessage, title, chatPath }
  },
})
</script>

<style lang="scss" scoped>
@use '@/stylesheets/application/resources';

.notification-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  padding: 8px 0;

  @include resources.mobile {
    width: inherit;
  }
}

.notification-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 60px;
}

.notification-content {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding-right: 10px;
  width: 230px;
}

.comment {
  height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
}
</style>
