import type { UserForTheTeamScheduleTimelineFragment } from '@/src/graphql/operations'
import type { Workable } from '@/src/types'

type PlannedWork = UserForTheTeamScheduleTimelineFragment['plannedWorks'][number]
type DoneWork = UserForTheTeamScheduleTimelineFragment['doneWorks'][number]
export type LegacyWorkable = Workable & {
  userId: string
  burst?: boolean
  chat?: {
    id: string
  }
}

export const toLegacyWorkable = (workable: PlannedWork | DoneWork): LegacyWorkable => {
  return {
    id: workable.id,
    userId: workable.userId,
    from: workable.from,
    to: workable.to,
    title: workable.taskTemplate.title,
    colorHue: workable.taskTemplate.colorHue,
    taskTemplateId: workable.taskTemplate.id,
    note: workable.__typename === 'DoneWork' ? workable.note : '',
    burst: workable.__typename === 'DoneWork' ? workable.burst : undefined,
    chat: workable.__typename === 'DoneWork' && workable.chat ? workable.chat : undefined,
    companyLargeCategoryName: workable.taskTemplate.companyLargeCategory.name,
  }
}

// NOTE: ひとまず元の仕様と同じ構造に変換するようにしている
export const toLegacyWorkables = (workables: ReadonlyArray<PlannedWork | DoneWork>): readonly LegacyWorkable[] => {
  return workables.map((_) => toLegacyWorkable(_))
}
