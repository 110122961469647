import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import { watch } from 'vue'
import type { ChatQuery } from '@/src/graphql/operations'
import {
  CreateChatMessageDocument,
  DeleteChatMessageDocument,
  CreateChatReadMarkDocument,
  CreateDirectUploadDocument,
  ChatDocument,
  ChatMessageWasCreatedDocument,
  ChatMessageWasUpdatedDocument,
} from '@/src/graphql/operations'
import { useSorted } from '@/src/hooks/useSorted'
import { validateMutationResult } from '@/src/lib/apolloUtils'
import { directUpload } from '@/src/lib/directUpload'
import { getFileMetadata } from '@/src/lib/getFileMetadata'
import { isSupportedImageCompression, imageCompression } from '@/src/lib/imageCompression'
import { showSuccess } from '@/src/lib/toast'

gql`
  query Chat($id: ID!) {
    chat(id: $id) {
      id
      user {
        id
        ...userBasicInfo
      }
      source {
        ... on DoneWork {
          id
          note
          from
          to
          taskTemplate {
            id
            title
            team {
              id
              name
            }
            companyLargeCategory {
              id
              name
            }
          }
          createdAt
        }
        ... on StartMessage {
          id
          content
          createdAt
        }
        ... on FinishMessage {
          id
          content
          createdAt
        }
      }
      chatMessages {
        id
        ...chatMessageForChatMessage
      }
    }
  }

  mutation CreateChatMessage($chatId: ID!, $content: String!, $blobId: String) {
    createChatMessage(input: { chatId: $chatId, content: $content, blobId: $blobId }) {
      result {
        ... on ChatMessage {
          chat {
            id
            chatMessages {
              ...chatMessageBasicInfo
            }
          }
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  subscription ChatMessageWasCreated($chatId: ID!) {
    chatMessageWasCreated(chatId: $chatId) {
      chatMessage {
        id
        chat {
          id
          chatMessages {
            id
            ...chatMessageForChatMessage
          }
        }
      }
    }
  }

  subscription ChatMessageWasUpdated($chatId: ID!) {
    chatMessageWasUpdated(chatId: $chatId) {
      chatMessage {
        id
        ...chatMessageForChatMessage
      }
    }
  }
`

export type Chat = Required<ChatQuery>['chat']
export type ChatMessage = Chat['chatMessages'][number]

export type CreateChatMessage = ({ content, image }: { content: string; image?: File }) => Promise<boolean>
type DeleteChatMessage = (chatMessageId: string) => void

export type UseChatReturn = {
  chat: Ref<Chat | undefined>
  chatMessages: Ref<readonly ChatMessage[]>
  loading: Readonly<Ref<boolean>>
  createChatMessage: CreateChatMessage
  deleteChatMessage: DeleteChatMessage
}

export const useChat = (id: Ref<string>): UseChatReturn => {
  // NOTE: チャットを切り替えた際に前回ロード後に投稿されたメッセージが必ず読み込まれるようにデフォルトfetchPolicyを上書きしている
  const { result, loading, subscribeToMore } = useQuery(ChatDocument, () => ({ id: id.value }), {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const chat = computed(() => result?.value?.chat)
  const chatMessages = computed(() => result?.value?.chat?.chatMessages ?? [])

  subscribeToMore(() => ({
    document: ChatMessageWasCreatedDocument,
    variables: {
      chatId: id.value,
    },
  }))

  subscribeToMore(() => ({
    document: ChatMessageWasUpdatedDocument,
    variables: {
      chatId: id.value,
    },
  }))

  const { mutate: mutateCreateChatReadMark } = useMutation(CreateChatReadMarkDocument, {})
  watch(chatMessages, () => {
    mutateCreateChatReadMark({ chatId: id.value })
  })

  const { mutate: mutateCreateChatMessage } = useMutation(CreateChatMessageDocument)

  const { mutate: mutateDeleteChatMessage } = useMutation(DeleteChatMessageDocument)

  const { mutate: mutateCreateDirectUpload } = useMutation(CreateDirectUploadDocument)

  const uploadImage = async (file: File): Promise<string> => {
    const compressedFile = isSupportedImageCompression(file) ? await imageCompression(file) : file
    const input = await getFileMetadata(compressedFile)
    const mutationResult = await mutateCreateDirectUpload(input)
    if (!mutationResult?.data?.createDirectUpload) {
      throw new Error('mutateCreateDirectUpload error')
    }

    const { url, headers, signedBlobId } = mutationResult.data.createDirectUpload.directUpload
    await directUpload(url, JSON.parse(headers), compressedFile)
    return signedBlobId
  }

  const createChatMessage: CreateChatMessage = async ({ content, image }) => {
    const blobId = image ? await uploadImage(image) : undefined
    const mutationResult = await mutateCreateChatMessage({
      chatId: id.value,
      content,
      blobId,
    })

    return validateMutationResult(mutationResult?.data?.createChatMessage?.result)
  }

  const deleteChatMessage: DeleteChatMessage = async (chatMessageId) => {
    await mutateDeleteChatMessage({ id: chatMessageId })

    showSuccess('コメントを削除しました。')
  }

  const sortedChatMessages = useSorted(chatMessages, (a, b) => a.createdAt.localeCompare(b.createdAt))

  return {
    chat,
    chatMessages: sortedChatMessages,
    loading,
    createChatMessage,
    deleteChatMessage,
  }
}
