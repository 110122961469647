import { useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import { SubscribeUnreadChatsCountWasUpdatedDocument, UnreadChatsCountDocument } from '@/src/graphql/operations'

export const useUnreadChatCount = (userId: Ref<string>): Readonly<Ref<number>> => {
  const { result, subscribeToMore } = useQuery(UnreadChatsCountDocument)

  subscribeToMore(() => ({
    document: SubscribeUnreadChatsCountWasUpdatedDocument,
    variables: {
      userId: userId.value,
    },
  }))

  return computed(() => result?.value?.currentUser.unreadChatsCount ?? 0)
}
