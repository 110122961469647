import { createGlobalState } from '@vueuse/core'

export type CurrentUser = NonNullable<typeof window.APP.rails.userData>

export const useCurrentUser = createGlobalState<() => CurrentUser>(() => {
  if (!window.APP.rails.userData) {
    throw new Error('userData is undefined')
  }

  return window.APP.rails.userData
})
