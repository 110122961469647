<script lang="ts" setup>
const props = defineProps<{
  src: string
  type?: 'small' | 'middle'
}>()
const classes = computed(() => {
  return {
    'is-small': props.type === 'small',
    'is-middle': props.type === 'middle',
  }
})
</script>

<template>
  <div class="img-wrapper" :class="classes">
    <img class="face-image" :src="src" />
  </div>
</template>

<style scoped lang="scss">
// TODO: IE以外では指定不要かどうか確認して削除する
// NOTE: IEでのsvg画像のサイズ崩れ防止の為に親要素に対してサイズ指定している
.img-wrapper {
  height: 50px;
  width: 50px;

  &.is-small {
    height: 25px;
    width: 25px;
  }

  &.is-middle {
    height: 35px;
    width: 35px;
  }
}

.face-image {
  border-radius: 50%;
}
</style>
