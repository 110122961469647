<template>
  <FooterBanner variant="success" data-test-selector="not-planned-banner">
    <span>おはようございます！<br class="is-hidden-tablet" />今日の予定を入力しましょう。</span>
    <a class="button is-primary is-inverted is-rounded mt-4" :href="editScheduleTimelinePath()"> 予定登録する </a>
  </FooterBanner>
</template>

<script lang="ts">
import { editScheduleTimelinePath } from '@/src/lib/rails-routes'

export default defineComponent({
  setup() {
    return { editScheduleTimelinePath }
  },
})
</script>
